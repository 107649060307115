<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tarifarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">
                    <router-link to="/tif">Tif</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administración</li>
                  <li class="breadcrumb-item active">Gestión Contratos</li>
                  <li class="breadcrumb-item active">Tarifarios</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="$store.getters.can('tif.tarifarios.create')"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Observaciones</th>
                      <th>Estado</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="tarifario in tarifarios.data"
                      :key="tarifario.id"
                    >
                      <td>{{ tarifario.id }}</td>
                      <td>{{ tarifario.nombre }}</td>
                      <td>{{ tarifario.observaciones }}</td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            tarifario.estado == 1
                              ? 'badge-warning'
                              : 'badge-success'
                          "
                        >
                          {{ tarifario.nEstado }}
                        </span>
                      </td>
                      <td class="text-right" style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can(
                                'tif.tarifarios.show'
                              )
                            "
                            @click="edit(tarifario)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can(
                                'tif.tarifarios.delete'
                              )
                            "
                            :disabled="tarifario.estado != 1"
                            @click="destroy(tarifario.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="tarifarios.total">
                  <p>
                    Mostrando del <b>{{ tarifarios.from }}</b> al
                    <b>{{ tarifarios.to }}</b> de un total:
                    <b>{{ tarifarios.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="tarifarios"
                  @pagination-change-page="getIndex"
                  :limit="10"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
    
    <script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";

export default {
  name: "TfiTarifariosIndex",
  components: {
    pagination,
    Loading,
  },
  data() {
    return {
      filtros: {},
      tarifarios: {},
      cargando: false,
      page: 1,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.page = page;
      this.cargando = true;
      axios
        .get("/api/tif/tarifarios?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.tarifarios = response.data;
          this.cargando = false;
        });
    },

    create() {
      return this.$router.push({
        name: "/Tif/TarifariosForm",
        params: { accion: "Crear", id: null, act: 1 },
      });
    },

    edit(tarifario) {
      return this.$router.push({
        name: "/Tif/TarifariosForm",
        params: { accion: "Editar", data_edit: tarifario, id: tarifario.id, act: 2},
      });
    },

  },
  mounted() {
    this.getIndex();
  },
};
</script>
    